<template>
	<div>
		<Header :childIndex="4"></Header>
		<div
			class="main_box"
			:style="
				!$UserID
					? 'height:980px; overflow: hidden;'
					: $ServiceType != 1
					? 'height:980px; overflow: hidden;'
					: 'height:auto; overflow: auto;'
			"
		>
			<div class="main_box1">
				<div class="title_box">
					<router-link :to="{ path: '/' }">
						<p>首页 ></p>
					</router-link>
					<router-link :to="{ path: '/LegacyIndex' }">
						<p>遗珍 ></p>
					</router-link>
					<span>图片详情</span>
				</div>
				<div class="book_detail img_detail">
					<div class="img_detailCover" v-if="ImageModelInfo">
						<img
							:src="
								$ImgUrl +
									ImageModelInfo.ImageModel.image_file_path
							"
							:onerror="$parent.defaultImg1"
						/>
					</div>
					<div
						class="yjcg_box13 book_detailbox"
						v-if="ImageModelInfo"
					>
						<div class="detail_title">
							<h3>{{ ImageModelInfo.ImageModel.image_name }}</h3>
						</div>
						<div class="qk_rightbox book_detailboxc img_detailboxc">
							<div class="qk_rightbox_b tp_rightbox_b">
								<i class="w_25">
									<p>图片尺寸：</p>
									<span
										>{{
											ImageModelInfo.ImageModel
												.image_width
										}}*{{
											ImageModelInfo.ImageModel
												.image_height
										}}</span
									>
								</i>
								<i
									class="w_25"
									v-if="
										ImageModelInfo.ImageModel.image_format
									"
								>
									<p>文件格式：</p>
									<span>{{
										ImageModelInfo.ImageModel.image_format
									}}</span>
								</i>
								<i class="w_25">
									<p>文件大小：</p>
									<span
										>{{
											(ImageModelInfo.ImageModel
												.image_file_size /
												1024)
												| toFixed(2)
										}}KB</span
									>
								</i>
								<i
									class="w100"
									v-if="ImageModelInfo.ImageModel.res_name"
								>
									<p>图片来源：</p>
									<span class="lin_height20p_t14">
										<a
											href="javascript:void(0)"
											@click="
												toBookDetail(
													ImageModelInfo.ImageModel
														.res_id
												)
											"
											>{{
												ImageModelInfo.ImageModel
													.res_name
											}}</a
										>
									</span>
								</i>
								<i
									style="width:calc( 100% - 320px )"
									v-if="ImageModelInfo.Keywords.length > 0"
								>
									<p>关键词:</p>
									<span class="lin_height20p_t14 p_t16">
										<a
											href="javascript:void(0)"
											:style="
												value.type == 0 ||
												value.ReleaseStatus == 0
													? 'color:#000; cursor: default;'
													: 'color:#99533e'
											"
											@click="toElementDetail(value)"
											v-for="(value,
											index) in ImageModelInfo.Keywords"
											:key="index"
											>{{ value.keyword }}</a
										>
									</span>
								</i>
							</div>
							<div class="opreation_box">
								<!-- 未收藏 -->
								<a
									href="javascript:void(0)"
									v-if="collectStatus == 0"
									class="detail_collection"
									@click="InsertCollection()"
									>收 藏</a
								>
								<!-- 已收藏 -->
								<a
									href="javascript:void(0)"
									v-if="collectStatus == 1"
									class="detail_collection detail_collected"
									@click="CancelCollection()"
									>已收藏</a
								>
								<!-- 预览 -->
								<!-- <a href="javascript:void(0)" class="review_btn" v-if="!$UserID || $ServiceType != 1" @click="ReviewImage">查 看</a>
								<a href="javascript:void(0)" class="review_btn" v-if="$UserID && $ServiceType == 1"  @click="ReviewImage">查 看
								</a> -->
								<!-- <el-image-viewer style="z-index: 200000;" :on-close="()=>{fileimage=false}" v-if="fileimage" :url-list="imageList"></el-image-viewer> -->
								<!-- 下载 -->
								<!-- <a href="javascript:void(0)" @click="DownloadImage()" class="imgDownload_btn">下 载</a> -->
							</div>
						</div>
						<div class="yjcg_box13cb">
							<div class="yjcg_box13cbb">
								{{ ImageModelInfo.ImageModel.description }}
							</div>
						</div>
					</div>
					<!-- 相关图片 -->
					<RelatedImage
						:relatedType="3"
						:topNum="5"
						:resID="$route.query.id"
					></RelatedImage>
					<!-- 相关知识元 -->
					<RelatedElement
						:relatedType="3"
						:topNum="4"
						:resID="$route.query.id"
					></RelatedElement>
					<!-- 相关条目 -->
					<RelatedEntry
						:relatedType="3"
						:topNum="12"
						:resID="$route.query.id"
					></RelatedEntry>
					<!-- 相关图书 -->
					<RelatedBook
						:relatedType="3"
						:topNum="8"
						:resID="$route.query.id"
					></RelatedBook>
					<!-- 相关音频 -->
					<RelatedAudio
						:relatedType="3"
						:topNum="5"
						:resID="$route.query.id"
					></RelatedAudio>
					<!-- 相关视频 -->
					<RelatedVideo
						:relatedType="3"
						:topNum="6"
						:resID="$route.query.id"
					></RelatedVideo>
				</div>
			</div>
			<VIPLayer
				v-show="!$UserID || $ServiceType != 1"
				:LayerHeight="340"
			></VIPLayer>
		</div>
		<Footer></Footer>
		<LoginPop :showLogin="showLogin"></LoginPop>
	</div>
</template>

<script src="./ImageDetail.js"></script>

<style>
.el-image-viewer__close {
	color: #fff;
}
</style>
